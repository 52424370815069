.component {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  & > :not(:last-child) {
    margin-right: 0 var(--size-20);

    @media (--viewport-lg) {
      margin-right: var(--size-40);
    }
  }
}

.item {
  white-space: nowrap;
  font-family: var(--font-family-alt);
  font-weight: var(--font-weight-demi);
  font-size: var(--font-size-18);
  color: var(--color-white);
}
