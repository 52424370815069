.component {
  position: relative;

  & > .marketingIntro {
    margin-bottom: var(--size-40);
    max-width: var(--rowTextWidth);
  }

  & > .marketingText {
    max-width: var(--rowTextWidth);
  }

  & > .marketingImage {
    position: absolute;
    bottom: calc(-1 * var(--size-120));
    right: 0;
  }
}

.marketingImage {
  display: none;

  @media (--viewport-lg) {
    filter: drop-shadow(0 10px 20px rgba(0, 0, 0, 0.1));
    display: block;
  }
}
