.component {
  display: flex;
  justify-content: center;
  padding-left: var(--size-20);
  padding-right: var(--size-20);

  @media (--viewport-md) {
    padding-left: var(--size-40);
    padding-right: var(--size-40);
  }

  & > * { width: 100%; }

  & > .sm { max-width: var(--container-sm); }
  & > .md { max-width: var(--container-md); }
  & > .lg { max-width: var(--container-lg); }
  & > .xl { max-width: var(--container-xl); }
}
