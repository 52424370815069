.componentXl {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-40);

  @media (--viewport-md) {
    font-size: var(--font-size-60);
  }
}

.componentLg {
  font-weight: var(--font-weight-bold);
  font-size: var(--font-size-30);

  @media (--viewport-md) {
    font-size: var(--font-size-40);
  }
}

.componentMd {
  font-weight: var(--font-weight-demi);
  font-size: var(--font-size-22);

  @media (--viewport-md) {
    font-size: var(--font-size-24);
  }
}

.componentSm {
  font-weight: var(--font-weight-demi);
  font-size: var(--font-size-20);

  @media (--viewport-md) {
    font-size: var(--font-size-22);
  }
}
