.page {
  position: relative;
  z-index: 0;

  & > .introAnimation {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 3;
    height: 100vh;
  }

  & > .scrollIndicator {
    top: 0;
    position: fixed;
    z-index: 2;
    width: 100%;
  }

  & > .siteHeader {
    z-index: 1;

    @media (--viewport-lg) {
      top: 0;
      position: sticky;
    }
  }

  & > .sectionWithId {
    @media (--viewport-lg) {
      scroll-margin-top: var(--siteHeaderHeight);
    }
  }
}
