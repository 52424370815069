.component {
  & > .image {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
}

.image {
  object-fit: cover;
}
