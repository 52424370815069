.componentBase { cursor: pointer; }
.icon { display: block; }

.componentInline,
.componentBlock {
  color: inherit;

  &.defaultUnderline {
    text-decoration: underline;

    &:hover,
    &:focus { text-decoration: none; }
  }

  &:not(.defaultUnderline) {
    text-decoration: none;

    &:hover,
    &:focus { text-decoration: underline; }
  }
}

.componentInline { display: inline; }
.componentBlock { display: block; }
