.component {
  display: flex;
  flex-direction: column;

  & > .contactInfo {
    margin-top: var(--size-80);
  }

  & > * {
    max-width: var(--rowTextWidth);
  }
}
