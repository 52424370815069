.animationWrapper {
  background-color: var(--color-green-700);
  position: relative;

  & > .animation {
    width: 100vw;
  }

  & > .skipButton {
    position: absolute;
    bottom: var(--size-40);
    right: var(--size-40);

    @media (--viewport-md) {
      bottom: var(--size-80);
      right: var(--size-80);
    }
  }
}

.animation {
  clip-path: polygon(0 1px, 100% 1px, 100% calc(100% - 1px), 0 calc(100% - 1px)); /* Remove pixel bug */
}

.skipButton {
  font-family: var(--font-family-alt);
  font-weight: var(--font-weight-demi);
  font-size: var(--font-size-18);
  color: var(--color-green-200);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
