.component {
  display: flex;
  justify-content: space-between;

  & > :not(:last-child) {
    margin-bottom: var(--size-40);
  }

  & > .text {
    max-width: var(--rowTextWidth);
  }

  & > .image {
    max-width: 350px;
    margin-left: var(--size-40);
    margin-right: var(--size-80);
  }
}

.image {
  filter: drop-shadow(-2px 2px 20px rgba(0, 0, 0, 0.1));
  display: none;

  @media (--viewport-lg) {
    display: block;
  }
}

.alignVertical {
  flex-direction: column;
}
