.componentBase {
  &.lightOnDark {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.componentLg {
  padding: 350px  0 var(--size-40);
}

.componentMd {
  padding: 175px  0 var(--size-40);
}

.componentSm {
  padding: var(--size-40) 0;
}
