.component {
  display: grid;
  grid-gap: var(--size-40);

  & > * {
    min-width: 0;
  }

  @media (--viewport-sm) {
    grid-gap: var(--size-80);
    grid-template-columns: repeat(2, 1fr);
  }
}

.alignCenter {
  justify-items: center;
}
