.component {
  overflow: hidden;
  padding: var(--size-40) 0;

  @media (--viewport-sm) { padding: var(--size-80) 0; }
  @media (--viewport-md) { padding: var(--size-100) 0; }
  @media (--viewport-lg) { padding: var(--size-120) 0; }

  &.lightOnDark {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
