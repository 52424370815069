.component {
  background-color: var(--color-green-700);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.layout {
  padding-bottom: var(--size-40);
  display: flex;
  gap: var(--size-20);
  justify-content: space-between;
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
    align-items: center;
  }
}

.logoWrapper {
  & > .logo {
    height: 24px;
    width: auto;

    @media (--viewport-md) {
      height: 34px;
    }
  }
}

.copyright {
  font-size: var(--font-size-14);

  @media (--viewport-md) {
    font-size: var(--font-size-16);
  }
}
