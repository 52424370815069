.component {
  display: grid;
  grid-template-columns: minmax(var(--size-20), 1fr) auto minmax(var(--size-20), 1fr);

  @media (--viewport-lg) {
    grid-template-columns: minmax(var(--size-40), 1fr) minmax(0, 410px) minmax(0, 910px) minmax(var(--size-40), 1fr);
  }

  & > .merkverhaalShortCopy {
    grid-column: 2;
    margin-bottom: var(--size-40);

    @media (--viewport-lg) {
      grid-column: 2;
      margin-bottom: 0;
    }
  }

  & > .merkverhaalLongCopy {
    grid-column: 2;

    @media (--viewport-lg) {
      grid-column: 3;
    }
  }
}
