:root {
  --size-5: 5px;
  --size-10: 10px;
  --size-20: 20px;
  --size-25: 25px;
  --size-40: 40px;
  --size-60: 60px;
  --size-80: 80px;
  --size-100: 100px;
  --size-120: 120px;

  --siteHeaderHeight: 100px;
  --rowTextWidth: 600px;
}
