.component {
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 0;

  & > * {
    z-index: 1;
    position: relative;

    @media (--viewport-lg) {
      width: 400px;
    }
  }
}
