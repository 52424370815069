.component {
  display: flex;
  flex-direction: column;

  & > :not(:last-child) {
    margin-bottom: var(--size-40);
  }

  & > .text {
    max-width: var(--rowTextWidth);
  }
}
