:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray-300: #ece4df;
  --color-gray-500: #bdb4ae;
  --color-gray-700: #565552;
  --color-green-200: #a7ff00;
  --color-green-700: #005141;
  --color-orange-500: #ff6700;

  --rgb-dark-grey: 86, 85, 82;
}

:export {
  brightGreen: var(--color-green-200);
  darkGreen: var(--color-green-700);
  orange: var(--color-orange-500);
  lightGrey: var(--color-gray-300);
  midGrey: var(--color-gray-500);
  darkGrey: var(--color-gray-700);
  white: var(--color-white);
  black: var(--color-black);
}
