:root {
  --font-family-base: 'MaisonNeue', sans-serif;
  --font-family-alt: 'MaisonNeueExtended', sans-serif;

  /* Sizes */
  --font-size-14: 1.4rem;
  --font-size-16: 1.6rem;
  --font-size-18: 1.8rem;
  --font-size-20: 2rem;
  --font-size-22: 2.2rem;
  --font-size-24: 2.4rem;
  --font-size-25: 2.5rem;
  --font-size-30: 3rem;
  --font-size-40: 4rem;
  --font-size-60: 6rem;

  /* Weights */
  --font-weight-regular: 400;
  --font-weight-demi: 600;
  --font-weight-bold: 700;
}
