.componentLg {
  font-family: var(--font-family-alt);
  font-weight: var(--font-weight-demi);
  font-size: var(--font-size-20);

  @media (--viewport-md) {
    font-size: var(--font-size-25);
  }
}

.componentMd {
  font-family: var(--font-family-alt);
  font-weight: var(--font-weight-demi);
  font-size: var(--font-size-18);

  @media (--viewport-md) {
    font-size: var(--font-size-20);
  }
}

.componentSm {
  font-size: var(--font-size-14);

  @media (--viewport-md) {
    font-size: var(--font-size-16);
  }
}
