.component {
  display: none;

  @media (--viewport-lg) {
    display: block;
    background-color: var(--color-green-700);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.layout {
  display: flex;
  gap: var(--size-20);
  justify-content: space-between;
  align-items: center;
  height: var(--siteHeaderHeight) !important;

  & > .nav {
    width: 100%;
    max-width: 920px;
  }
}

.languageSwitch {
  font-family: var(--font-family-alt);
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-18);
  color: var(--color-green-200);
  text-decoration: underline;
}
