.component {
  padding-top: var(--size-40);
  border-top: 1px solid rgba(var(--rgb-dark-grey), 0.2);
  display: grid;

  @media (--viewport-md) {
    padding-top: 0;
    border-top: none;
    grid-template-columns: 2fr 1fr;
    grid-column-gap: var(--size-120);
  }

  & > .content {
    @media (--viewport-md) {
      order: -1;
    }
  }
}

.description {
  @media (--viewport-md) {
    padding-bottom: var(--size-40);
  }
}

.content {
  padding: var(--size-40) 0;

  @media (--viewport-md) {
    padding: var(--size-80) 0;
    border-top: 1px solid rgba(var(--rgb-dark-grey), 0.2);
  }

  & > :not(:last-child) {
    margin-bottom: var(--size-40);
  }

  & > * {
    margin-left: auto;
    margin-right: auto;
  }
}
