.component {
  background-color: var(--color-green-700);

  & > .progress {
    height: 5px;
    width: 100%;
  }
}

.progress {
  background-color: var(--color-green-200);
  transform-origin: left top;
  will-change: transform;
}
